<template>
    <div class="">
      <user-form ref="form" action="Save" v-model="formData" @formSubmitted="storeUser"/>
    </div>
  </template>
  
  <script>
  import UserForm from "@/views/components/users/UserForm.vue";
  import UserService from "@/services/UserService";
  
  export default {
    name: "CreateOffer",
    components: {
      UserForm
    },
    data() {
      return {
        userService: new UserService(),
        formData: {
          created_at: new Date(),
          password: "12345678",
          show_password: true,
          status: true
        },
      }
    },
    methods: {
      storeUser() {
        this.$refs.form.loading = true;
        this.formData = { ...this.formData, email: this.formData.email + '@alaalamy.com'};
        this.userService.create(this.formData).then(res => {
          this.$router.push({name: 'all-users'})
        }).catch(err => {
          this.formData = { ...this.formData, email: this.formData.email.substr(0, this.formData.email.length - 12) };
        }).finally(() => {
          this.$refs.form.loading = false;
        })
      },
    }
  }
  </script>
  
  <style scoped>
  </style>
  